import { gql } from 'graphql-tag';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import FileFragment from '@fragments/shared/FileFragment';

export default gql`
    fragment HeadingBlockFragment on HeadingBlock {
        blockName
        blockType
        eyebrow
        HeadingBlock___header: header {
            headerTitle
            type
        }
        subheader
        buttons {
            id
            HeadingBlock___button: button {
                appearance
                type
                label
                reference {
                    ...SummaryFragment
                }
                file {
                    ...FileFragment
                }
                url
                socialNetworks {
                    network
                    handle
                    id
                }
                newTab
            }
        }
    }
    ${SummaryFragment}
    ${FileFragment}
`;
