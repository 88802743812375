import { gql } from 'graphql-tag';

import ContentCardBlockFragment from '@fragments/shared/ContentCardBlockFragment';
import ContentSliderBlockFragment from '@fragments/shared/ContentSliderBlockFragment';
import GalleryBlockFragment from '@fragments/shared/GalleryBlockFragment';
import HtmlBlockFragment from '@fragments/shared/HtmlBlockFragment';
import ImageHeadingBlockFragment from '@fragments/shared/ImageHeadingBlockFragment';
import ImageBlockFragment from '@fragments/shared/ImageBlockFragment';
import SubscribeBlockFragment from '@fragments/shared/SubscribeBlockFragment';
import VideoBlockFragment from '@fragments/shared/VideoBlockFragment';

export default gql`
    fragment StreamTierFullBleedFragment on Full_bleed {
        columnLayout
        full_bleed___colorScheme: colorScheme
        full_bleed___topPadding: topPadding
        full_bleed___bottomPadding: bottomPadding
        blocks {
            ...ContentCardBlockFragment
            ...ContentSliderBlockFragment
            ...GalleryBlockFragment
            ...HtmlBlockFragment
            ...ImageBlockFragment
            ...ImageHeadingBlockFragment
            ...SubscribeBlockFragment
            ...VideoBlockFragment
        }
        blockName
        blockType
    }

    ${ContentCardBlockFragment}
    ${ContentSliderBlockFragment}
    ${GalleryBlockFragment}
    ${HtmlBlockFragment}
    ${ImageBlockFragment}
    ${ImageHeadingBlockFragment}
    ${SubscribeBlockFragment}
    ${VideoBlockFragment}
`;
