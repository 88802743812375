import { gql } from 'graphql-tag';
import PersonFragment from '@fragments/pages/PersonFragment';
import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';

export default gql`
    fragment ExpertHighlightsBlockFragment on ExpertHighlightsBlock {
        blockName
        blockType
        experts {
            id
            person {
                ...PersonFragment
            }
            featuredContent {
                ...ContentDynamicSortableFragment
            }
        }
        showExpertTags
    }
    ${PersonFragment}
    ${ContentDynamicSortableFragment}
`;
