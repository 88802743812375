import { gql } from 'graphql-tag';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';
import SummaryFragment from '@fragments/summaries/SummaryFragment';

export default gql`
    fragment PersonFeedBlockFragment on PersonFeedBlock {
        blockName
        blockType
        people
        excludedPeople {
            ...PersonSummaryFragment
        }
        additionalPeople {
            ...PersonSummaryFragment
        }
    }
    ${PersonSummaryFragment}
    ${SummaryFragment}
`;
