import { gql } from 'graphql-tag';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';

import FileFragment from '@fragments/shared/FileFragment';

export default gql`
    fragment ContentSliderBlockFragment on ContentSliderBlock {
        blockName
        blockType
        eyebrow
        sliderTitle
        subheader
        ContentSliderBlock___button: button {
            appearance
            label
            type
            url
            reference {
                ...SummaryFragment
            }
            file {
                ...FileFragment
            }
            socialNetworks {
                handle
                id
                network
            }
            newTab
        }
        contentCards {
            id
            contentCard {
                id
                overrideImage {
                    ...ImageSummaryFragment
                }
                content {
                    ...SummaryFragment
                }
            }
        }
    }
    ${SummaryFragment}
    ${FileFragment}
    ${ImageSummaryFragment}
`;
