import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import CollectionSummaryFragment from '@fragments/summaries/CollectionSummaryFragment';
import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';

export default gql`
    fragment CollectionBlockFragment on CollectionBlock {
        blockType
        buttonTextOverride
        titleOverride
        dekOverride
        featuredContent {
            ...ContentDynamicSortableFragment
        }
        targetCollection {
            ... on Collection {
                ...CollectionSummaryFragment
            }
        }
    }
    ${CollectionSummaryFragment}
    ${ImageSummaryFragment}
    ${SummaryFragment}
    ${ContentDynamicSortableFragment}
`;
