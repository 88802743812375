import { gql } from 'graphql-tag';
import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';

import TopicSummaryFragment from '@fragments/summaries/TopicSummaryFragment';
import RegionSummaryFragment from '@fragments/summaries/RegionSummaryFragment';

export default gql`
    fragment TagFeedBlockFragment on TagFeedBlock {
        blockName
        blockType
        topicRegionContentFeed {
            topicOrRegion {
                value {
                    ...TopicSummaryFragment
                    ...RegionSummaryFragment
                }
            }
            featuredContent {
                ...ContentDynamicSortableFragment
            }
        }
    }
    ${ContentDynamicSortableFragment}
    ${TopicSummaryFragment}
    ${RegionSummaryFragment}
`;
