import { gql } from 'graphql-tag';
import ContentDynamicSortableFragment from '@fragments/shared/ContentDynamicSortableFragment';

export default gql`
    fragment ContentFeedBlockFragment on ContentFeedBlock {
        blockName
        blockType
        contentFeed {
            ...ContentDynamicSortableFragment
        }
    }
    ${ContentDynamicSortableFragment}
`;
