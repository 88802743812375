import { gql } from 'graphql-tag';

import AffiliationCardBlockFragment from '@fragments/shared/AffiliationCardBlockFragment';
import ButtonBlockFragment from '@fragments/shared/ButtonBlockFragment';
import ContentCardBlockFragment from '@fragments/shared/ContentCardBlockFragment';
import ContentFeedBlockFragment from '@fragments/shared/ContentFeedBlockFragment';
import EventPanelBlockFragment from '@fragments/shared/EventPanelBlockFragment';
import GalleryBlockFragment from '@fragments/shared/GalleryBlockFragment';
import HeadingBlockFragment from '@fragments/shared/HeadingBlockFragment';
import HtmlBlockFragment from '@fragments/shared/HtmlBlockFragment';
import ImageBlockFragment from '@fragments/shared/ImageBlockFragment';
import PersonBlockFragment from '@fragments/shared/PersonBlockFragment';
import PullQuoteBlockFragment from '@fragments/shared/PullQuoteBlockFragment';
import RichTextBlockFragment from '@fragments/shared/RichTextBlockFragment';
import SubscribeBlockFragment from '@fragments/shared/SubscribeBlockFragment';
import TagFeedBlockFragment from '@fragments/shared/TagFeedBlockFragment';
import VideoBlockFragment from '@fragments/shared/VideoBlockFragment';

export default gql`
    fragment StreamTierW252550Fragment on W25_25_50 {
        columnLayout
        w25_25_50___colorScheme: colorScheme
        w25_25_50___topPadding: topPadding
        w25_25_50___bottomPadding: bottomPadding
        w25_25_50___alignment: alignment
        blocks {
            ...AffiliationCardBlockFragment
            ...ButtonBlockFragment
            ...ContentCardBlockFragment
            ...ContentFeedBlockFragment
            ...EventPanelBlockFragment
            ...GalleryBlockFragment
            ...HeadingBlockFragment
            ...HtmlBlockFragment
            ...ImageBlockFragment
            ...PersonBlockFragment
            ...PullQuoteBlockFragment
            ...RichTextBlockFragment
            ...SubscribeBlockFragment
            ...TagFeedBlockFragment
            ...VideoBlockFragment
        }
        blockName
        blockType
    }
    ${AffiliationCardBlockFragment}
    ${ButtonBlockFragment}
    ${ContentCardBlockFragment}
    ${ContentFeedBlockFragment}
    ${EventPanelBlockFragment}
    ${GalleryBlockFragment}
    ${HeadingBlockFragment}
    ${HtmlBlockFragment}
    ${ImageBlockFragment}
    ${PersonBlockFragment}
    ${PullQuoteBlockFragment}
    ${RichTextBlockFragment}
    ${SubscribeBlockFragment}
    ${TagFeedBlockFragment}
    ${VideoBlockFragment}
`;
