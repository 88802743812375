import { gql } from 'graphql-tag';
import SummaryFragment from '@fragments/summaries/SummaryFragment';

export default gql`
    fragment AffiliationCardBlockFragment on AffiliationCardBlock {
        blockName
        blockType
        id
        affiliations {
            id
            showImage
            showSubscribeCta
            content {
                ...SummaryFragment
            }
        }
    }
    ${SummaryFragment}
`;
