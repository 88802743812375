import gql from 'graphql-tag';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';

export default gql`
    fragment CollectionSummaryFragment on Collection {
        path
        title
        dek
        imageCollage
        contributors {
            ...PersonSummaryFragment
        }
    }

    ${PersonSummaryFragment}
`;
