import { gql } from 'graphql-tag';
import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import SummaryFragment from '@fragments/summaries/SummaryFragment';
import FileFragment from '@fragments/shared/FileFragment';

export default gql`
    fragment ImageHeadingBlockFragment on ImageHeadingBlock {
        blockName
        blockType
        ImageHeadingBlock___type: type
        buttons {
            id
            ImageHeadingBlock___button: button {
                appearance
                type
                label
                reference {
                    ...SummaryFragment
                }
                file {
                    ...FileFragment
                }
                url
                socialNetworks {
                    network
                    handle
                    id
                }
                newTab
            }
        }
        ImageHeadingBlock___header: header {
            headerTitle
            type
        }
        subheader
        ImageHeadingBlock___image: image {
            ...ImageSummaryFragment
        }
        accentColor
    }
    ${ImageSummaryFragment}
    ${SummaryFragment}
    ${FileFragment}
`;
